import React, { useState } from "react";

import "./closeoptions.scss";

const CloseOptions = (props:any) => {
  const eventOptions = [
    {
      text: "Close",
      id: 1,
    },
    {
      text: "Restart",
      id: 2,
    }
   
  ];

const [activeIndex, setActiveIndex] = useState(null);
const [isDisabled, setIsDisabled] = useState(false);
const [ clicked, setIsClicked] = useState(false);

  const buttonsMarkup = eventOptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsClicked(true);setIsDisabled(!isDisabled);setActiveIndex(index);

        switch(option.id){
            case 1:props.actionProvider.handleCloseBot();  break;
            case 2:props.actionProvider.handleOptions(); break;
        }

        }} className={activeIndex === index?"change-buttons":"option-button"}  disabled={isDisabled}>
            {option.text}
            </button>
        ));


  return <div className="options-container">{buttonsMarkup}
</div>;
};

export default CloseOptions;
