import React, { useState } from "react";

import "./notioptions.scss";

const NotiOptions = (props:any) => {
  const eventOptions = [
    {
      text: "Too many notifications",
      id: 1,
    },
    {
      text: "Misising notifications",
      id: 2,
    },
     {
      text: "Something else",
      id: 3,
    }
   
  ];


function restart(){
return <div>
    <button className="show-more-link" onClick={props.actionProvider.handleOptions} >
          Didn’t find what you were looking for? Restart
    </button>
</div>
}

 const [activeIndex, setActiveIndex] = useState(null);
const [isDisabled, setIsDisabled] = useState(false);
const [isClicked, setIsClicked] = useState(false);

  const buttonsMarkup = eventOptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index);

switch(option.id){
case 1:props.actionProvider.displaySubscriptions(); break;
case 2:props.actionProvider.displaySubscriptions(); break;
case 3:setIsClicked(true);window.open('https://experienceleague.adobe.com/home?support-tab=home#support'); break;
}



}} className={activeIndex === index?"change-buttons":"option-button"}  disabled={isDisabled}>
      {option.text}
    </button>
  ));


  return <div className="options-container">{buttonsMarkup}
{isClicked?restart():<></>}
</div>;
};

export default NotiOptions;
