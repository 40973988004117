
import axios from 'axios';
import Spinner from '../../spinner/spinner';
import React, { useEffect, useState, useContext } from 'react';
import { ServiceRegistry } from '../../../models/service-registry';
import { SubscriptionService } from '../../../models/subscription-service';
import { createChatBotMessage, createCustomMessage } from "react-chatbot-kit";
class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }
  parse(message) {
  this.actionProvider.handleWait(message);
}
}

export default MessageParser;
