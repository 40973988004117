import axios, { AxiosPromise, AxiosResponse } from "axios";
import { EVENTTYPES, REGIONS, SUBMENU, URLS } from "../constants";
import { SubscriptionType } from "../proptype";
import { cloneArrayOfObject, isBizible, removeDuplicate } from "../utils";

export class BsoMapping {
  api_url: string;
mappingData:  any;

  private static instance: BsoMapping;
  private _lastModified: string = "";
  private _response: any;
  private _promise: AxiosPromise | null | undefined;
  private _headPromise: Promise<string> | null | undefined;

  private constructor() {
    this.api_url = URLS.BSO_MAP;
    this.mappingData = {};
  }

 



  private getLastModified() {
    if (!this._headPromise) {
      this._headPromise = new Promise((resolve, reject) => {
        axios
          .head(this.api_url)
          .then((response: AxiosResponse) => {
            delete this._headPromise;
            resolve(response.headers["last-modified"]);
          })
          .catch(() => {
            delete this._headPromise;
            reject("");
          });
      });
    }

    return this._headPromise;
  }

  private _fetch(clearCache?: boolean) {
    this._promise = axios.get(this.api_url);

    this._promise
      .then((result: AxiosResponse) => {
        if (result.status === 200 && result.data) {
          if (
            result.headers["last-modified"] &&
            this._lastModified !== result.headers["last-modified"]
          ) {
            this._response = null;
            this._lastModified = result.headers["last-modified"];
          }
          this.set(result.data, clearCache);
        }
        delete this._promise;
      })
      .catch(() => {
        this._response = null;
        delete this._promise;
      });

    

    return this._promise;
  }

  public static init() {
    if (!BsoMapping.instance) {
      BsoMapping.instance = new BsoMapping();
    }
    return BsoMapping.instance;
  }


    public async fetch(clearCache?: boolean) {
        if (clearCache) {
            this._response = null;
        }
        if (this._promise) {
            return this._promise;
        }

        if (this._response) {
            let lastModifiedDate = '';
            try {
                if (this._headPromise) {
                    lastModifiedDate = await this._headPromise;
                } else {
                    await this.getLastModified();
                }
                if (lastModifiedDate && lastModifiedDate !== this._lastModified) {
                    return this._fetch();
                } else {
                    this.mappingData = JSON.parse(JSON.stringify(this._response.data));
                    return Promise.resolve(this._response);
                }
            } catch {
                this.mappingData = JSON.parse(JSON.stringify(this._response.data));
                return Promise.resolve(this._response);
            }
        }
        return this._fetch(clearCache);

    }

  public fetchCached() {
    if (this._response) {
      return Promise.resolve(this._response);
    } else {
      return this._promise ? this._promise : this._fetch();
    }
  }

  private set(result: any, clearCache?: boolean) {
    if (result) {
      this._response = !clearCache
        ? { data: JSON.parse(JSON.stringify(result)) }
        : null;
      this.mappingData = result;
    }
    return this.mappingData;
  }


}
