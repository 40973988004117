import React from "react";

import BotAvatar from "../../image/statusbot2.png";

const MyAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div
        className="react-chatbot-kit-chat-bot-avatar-container"
      >
        {/* <div className="react-chatbot-kit-chat-bot-avatar-letter">S</div> */}
        <img alt="BotAvatar" src={BotAvatar} />
      </div>
    </div>
  );
};

export default MyAvatar;