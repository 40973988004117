import React, { useEffect, useState, useContext } from 'react';
import { ConfigContext } from "../../App";
import { useHistory } from "react-router-dom";
import { createBrowserHistory } from 'history';
import parse from 'html-react-parser';
import { SubscriptionService } from '../../models/subscription-service';
import { EventService } from '../../models/event-service';
import EventTile from '../eventtile/eventtile';
import { ServiceRegistry } from '../../models/service-registry';
import { BsoMapping } from '../../models/mapping-service';
import Spinner from '../spinner/spinner';
import ProductTile from '../producttile/producttile';
import PNSHeader from '../pnsheader/pnsheader';
import { IMS_EVENT, OMEGA_ENV, SLACK_RELOAD_TIME } from '../../constants';
import OMEGA from '../../omega';
import { clearPageState, HideFooter } from '../../utils';
import { SlackStatus } from '../../proptype';

export default function ManageSubscription() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [subscribedEvents, setSubscribedEvents] = useState([]);
    const [selfSubscriptions, setSelfSubscriptions] = useState([]);
    const [slackSubscriptions, setSlackSubscriptions] = useState<any>({});
    const [userLanguage, setUserLanguage] = useState('');
    const [email, setEmail] = useState('');
    const [currentTab, setCurrentTab] = useState('product');
    const [langInfo, setLangInfo] = useState(false);
    const [slackInfo, setSlackInfo] = useState(false);
    const [timeZoneInfo, setTimeZoneInfo] = useState(false);
    const [timezone, setTimeZone] = useState();
    const [slackStatus, setSlackStatus] = useState(-1);
    const [mounted, setMounted] = useState(true);
    const [bsotogrpid, setBsoToGrpId] = useState({});
    const locales = useContext(ConfigContext).appConfig.languageData;
    const historyAPI = useHistory();

    const eventService: EventService = EventService.init();
    const subscriptionService: SubscriptionService = SubscriptionService.init();
    subscriptionService.setLocales(locales);
    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
    const bsomapping: BsoMapping = BsoMapping.init();

    useEffect(() => {
        let intervalId: any = null, timer: any = 0;
        let clearRefresh = () => {
            window.slackWindow = null;
            if (intervalId) {
                clearInterval(intervalId);
            }
            intervalId = null;
        }
        window.scrollTo(0, 0);
        setLoading(true);
        if (window.adobeIMS) {
            getSubscriptionData();
        } else {
            document.addEventListener(IMS_EVENT, getSubscriptionData);
        }

        if (window.slackWindow) {
            intervalId = setInterval(() => {
                if (window.slackWindow) {
                    try {
                        let slackPathName: string = window.slackWindow?.location.pathname;

                        if (slackPathName?.includes('slack/success')) {
                            clearRefresh();
                            window.location.reload();
                        }

                    } catch (e) { }
                } else {
                    clearRefresh();
                }

                timer += 200;
                if (timer > SLACK_RELOAD_TIME) {
                    clearRefresh();
                }

            }, 200);
        }

        // if (window.removeSlackWindow) {
        //     intervalId = setInterval(() => {
        //         if (window.removeSlackWindow) {
        //             subscriptionService.getSubscriptions("product").then((result: any) => {

        //                 let selfSubscriptionData = result.data;
        //                 if (selfSubscriptionData.slackStatus === SlackStatus.Revoked) {
        //                     clearRefresh();
        //                     window.location.reload();
        //                 }
        //             });
        //         } else {
        //             clearRefresh();
        //         }

        //         timer += 5000;
        //         if (timer > SLACK_RELOAD_TIME) {
        //             clearRefresh();
        //         }
        //     }, 5000)
        // }
        if (!window.slackWindow && intervalId) {
            clearRefresh();
        }

        return () => {
            setMounted(false);
            clearRefresh();
            document.removeEventListener(IMS_EVENT, getSubscriptionData);
        }
    }, []);


    function getSubscriptionData() {

        if (!window.adobeIMS?.isSignedInUser()) {
            window.adobeid.redirect_uri = window.location.href;
            window.adobeIMS.signIn();
            return;
        }

        OMEGA.init().updateAndTrack('page', {
            hierarchy: "home:manage_subscription",
            name: "Manage Subscription - Adobe status",
            url: encodeURIComponent(window.location.href),
            type: "pns",
            env: OMEGA_ENV
        });

        if (window.location.hash === '#event') {
            setCurrentTab('event');
        }

        let state: any = historyAPI.location.state;
        let safe: boolean = !!state?.page;
        clearPageState(createBrowserHistory());
        const [
            productPromise,
            servicePromise,
            subscriptionPromise,
            selfSubscriptionPromise,
            slackSubscriptionPromise,
            bsoPromise
        ]: any = [
                eventService.fetch(),
                serviceRegistry.fetch(),
                subscriptionService.getSubscriptions("event", safe),
                subscriptionService.getSubscriptions("product", safe),
                subscriptionService.getSlackSubscription(),
                bsomapping.fetch()
            ];

        Promise.all([subscriptionPromise, selfSubscriptionPromise, slackSubscriptionPromise, productPromise, servicePromise,bsoPromise])
            .then((result: any) => [result[0].data, result[1].data, result[2].data,result[3].data,result[4].data,result[5].data])
            .then((result: any) => {
                if (mounted) {
                    let mapping = result[5];
                    setBsoToGrpId(result[5]);
                    let [subscriptionData, selfSubscriptionData, slackSubscriptionData]: any = result;

                    if (slackSubscriptionData?.channel && slackSubscriptionData?.active) {
                        setSlackSubscriptions(slackSubscriptionData);
                    }


                    setUserLanguage(subscriptionService.getUserDetail().userLanguage);
                    setEmail(subscriptionService.getUserDetail().email);

                    let currentSubs = eventService.getSubscribedOngoingIncidents(subscriptionData);
                    setSubscribedEvents(currentSubs || []);
                    let timeZoneString: any = `${selfSubscriptionData.timeZone} (${selfSubscriptionData.timezoneOffset})`;
                    setTimeZone(timeZoneString);
                    setSlackStatus(selfSubscriptionData.slackStatus);
                    let selfSubs = serviceRegistry.mergeOfferingsIntoProduct(selfSubscriptionData ,mapping ) || [];

                    setSelfSubscriptions(selfSubs.sort((a: any, b: any) => a.product.name.localeCompare(b.product.name)));
                    let currentState: any = historyAPI.location.state;

                    if (!selfSubs?.length && currentSubs?.length) {

                        if (historyAPI.location && historyAPI.location.state) {
                            if (currentState?.tab?.length) {
                                setCurrentTab(currentState.tab);
                            }
                        }
                        else {
                            setCurrentTab('event');
                        }
                    } else {
                        if (currentState?.tab?.length) {
                            setCurrentTab(currentState.tab);
                        }
                    }

                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    }

    function getEventTiles() {
        return (
            subscribedEvents.map((incident: any, index: any) => {
                return (
                    <EventTile event={incident} key={index} isHistory={eventService.isCommunication(incident.eventType) ? true : false} isSubscribed={true} isPns={true} />
                );
            })
        );
    }

    function getProductTiles() {
        return (
            <>
                <div>
                    {selfSubscriptions.map((subscription: any) => {
                        return (
                            <ProductTile subscription={subscription} key={subscription.product.id} showAll={true} bsomapping={bsotogrpid} />
                        )
                    })
                    }
                </div>
            </>)
    }


    function switchTab(tab: any) {
        if (tab !== currentTab) {
            window.location.href = '#' + tab;
            setCurrentTab(tab);
            return true;
        }
        return false;
    }

    function renderSlack() {
        let slackText = '', slackInfoText = '';

        if (slackStatus === SlackStatus.Installed && slackSubscriptions.channel) {
            slackText = slackSubscriptions.channel;
            slackInfoText = locales.manage_subscription_slack_channel_icon_info;

        }
        if (slackStatus === SlackStatus.InstallPending) {
            slackText = locales.slack_setup_pending;
            slackInfoText = locales.manage_slack_setup_pending_info;

        }

        if (slackStatus === SlackStatus.RevokePending) {
            slackText = locales.slack_removal;
            slackInfoText = locales.manage_slack_removal_info;
        }

        if (slackText && slackInfoText && slackSubscriptions.workSpaceUrl) {
            var workspaceUrl = new URL(slackSubscriptions.workSpaceUrl)
            return (
                <>
                    <div> <i className="prof-icon slack-icon"></i> {locales.slack}: {slackText.startsWith('@') ? <b>{slackText}</b> : <a href={workspaceUrl.origin + '/channels/' + (slackText.startsWith('#') ? slackText.slice(1) : slackText)} rel="noreferrer" target="_blank" className="slack-channel-link"><b>{slackText.startsWith('#') ? slackText : `#${slackText}`}</b></a>}<i onClick={() => { setSlackInfo(!slackInfo); }} className="info-icon" /></div>                    {slackInfo ? <div className="slack-info">{parse(slackInfoText)}</div> : ""}
                </>
            );
        } else if (slackText && slackInfoText) {
            return (
                <div> <i className="prof-icon slack-icon"></i> {locales.slack}: <b>{slackText}</b><i onClick={() => { setSlackInfo(!slackInfo); }} className="info-icon" />{slackInfo ? <div className="slack-info">{parse(slackInfoText)}</div> : ""}</div>
            );
        } else {
            return <></>;
        }
    }

    function printEventInfo() {
        if (loading) {
            return <>{HideFooter(true)}<Spinner /></>;
        } else {
            HideFooter(false)
            if (subscribedEvents.length) {
                return (
                    <div className="tab-content event-content active ">
                        <div className="event-sub-container">
                            <div className="preference">
                                <div><i className="prof-icon email-icon"></i>{locales.sb_email}: <b>{email}</b></div>
                                {renderSlack()}
                                <div><i className="prof-icon lang-icon"></i>{locales.sb_lang}: <b>{userLanguage}</b><i onClick={() => { setLangInfo(!langInfo); }} className="info-icon" /></div>
                                {langInfo ? <div className="lang-info">{parse(locales.lang_info)}</div> : ""}
                                <div><i className="prof-icon timezone-icon"></i>{locales.timezone}: <b>{timezone}</b><i onClick={() => { setTimeZoneInfo(!timeZoneInfo); }} className="info-icon" /></div>
                                {timeZoneInfo ? <div className="lang-info">{parse(locales.timezone_info)}</div> : ""}
                            </div>
                            <div>{getEventTiles()}</div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="text-container no-subscription">
                        <div>{locales.no_subscription}</div>
                        <p>{parse(locales.no_event_susbscription_message)}</p>
                    </div>
                )
            }
        }
    }

    function printProductInfo() {
        if (loading) {
            return <>{HideFooter(true)}<Spinner /></>;
        } else {
            HideFooter(false)
            if (selfSubscriptions.length) {
                return (
                    <div className="animate__animated animate__fadeIn">
                        <div className="pull-right action-btn">
                            <button className="btn unsubscription" onClick={() => { historyAPI.push("/proactive-notifications/product/unsubscribe-all") }}>{locales.unsubscribe_all}</button>
                            <button className="btn edit-subscription" onClick={() => { historyAPI.push("/proactive-notifications/subscriptions/edit") }}>{locales.edit_subscriptions}</button>
                        </div>
                        <div className="preference">
                            <div> <i className="prof-icon email-icon"></i> {locales.sb_email}: <b>{email}</b></div>
                            {renderSlack()}
                            <div> <i className="prof-icon lang-icon"></i> {locales.sb_lang}: <b>{userLanguage}</b><i onClick={() => { setLangInfo(!langInfo); }} className="info-icon" /></div>
                            {langInfo ? <div className="lang-info">{parse(locales.lang_info)}</div> : ""}
                            <div> <i className="prof-icon timezone-icon"></i> {locales.timezone}: <b>{timezone}</b><i onClick={() => { setTimeZoneInfo(!timeZoneInfo); }} className="info-icon" /></div>
                            {timeZoneInfo ? <div className="lang-info">{parse(locales.timezone_info)}</div> : ""}
                        </div>
                        <div>{getProductTiles()}</div>
                    </div>
                );
            } else {
                return (
                    <div className="text-container animate__animated animate__fadeIn">
                        <div className="pull-right action-btn">
                            <button className="btn" onClick={() => { historyAPI.push("/proactive-notifications/subscriptions/create") }}>{locales.create_subscriptions}</button>
                        </div>
                        <div className="no-subscription">
                            <div>{locales.no_subscription}</div>
                            <p>{locales.no_subscription_messsage}</p>
                        </div>
                    </div>
                );
            }
        }
    }

    function renderTabContent() {
        return (currentTab === 'event' || window.location.href.indexOf("event") > -1) ? printEventInfo() : printProductInfo();
    }


    return (
        <div className="manage-subscription-container">
            <PNSHeader></PNSHeader>

            <div className="container subscription-container">
                <div className="subscription-tab">
                    <ul className="tabs">
                        <li className={currentTab === 'product' ? `active` : ""}
                            onClick={() => switchTab('product')}>{locales.product_subscriptions}</li>
                        <li className={currentTab === 'event' ? `active` : ""}
                            onClick={() => switchTab('event')}>{locales.event_subscriptions}</li>
                    </ul>
                    <div className="subscription-tab-content">
                        {error ? <div className="error-info">{parse(locales.api_error_overview)}</div> : renderTabContent()}
                    </div>
                </div>
            </div>
        </div>
    );
}
