/* eslint-disable react-hooks/exhaustive-deps */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import "./fonts/style.scss";
import './App.scss';
import Header from './components/header/header';
import ImsWebHelper from "./ims/imswebhelper";
import { ImsData } from "./ims/ims-data";
import Help from './components/help/help';
import Home from './components/home/home';
import Cloud from './components/cloud/cloud';
import Events from './components/events/events';
import EventSubscription from './components/eventSubscription/eventSubscription';
import Footer from './components/footer/footer';
import enlocales from "./locales/en.json";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { LocaleService } from './models/locale-service';
import ManageSubscription from './components/managesubscription/managesubscription';
import PnsUnsubscribeAll from './components/pnsunsubscribeall/pnsunsubscribeall';
import UnSubscribeProduct from './components/unsubscribeproduct/unsubscribeproduct';
import ProactiveNotification from './components/proactivenotification/proactivenotification';
import SlackLanding from './components/slack/slacklanding';
import SlackError from './components/slack/error';
import SlackSuccess from './components/slack/success';
import { ImsWebConstants, IMS_EVENT, OMEGA_ENV } from './constants';
import HistoryRedirection from './components/historyRediretcion/historyRediretcion';


export const ConfigContext = React.createContext<any>({});

function App() {
  const [appConfig, setAppConfig] = useState({
    languageData: enlocales,
    menu: 'Overview'
  });
  const IMSEvent = new Event(IMS_EVENT);

  const locales: any = LocaleService.init();

  function updateMenu(menu: any) {
    setAppConfig({
      languageData: locales?.localeData || enlocales,
      menu: menu
    });
  }
  const context: any = {
    updateMenu,
    appConfig
  };
  const onStateChanged = (state: any) => {
    if (state.isSignedInUser) {
      window.adobeIMS?.validateToken();
      window.adobeIMS?.getProfile()
        .then((profile: any) => sessionStorage.setItem('adobeid_ims_profile', JSON.stringify(profile)));
    }
    if (state?.ready) {
      if (state.isSignedInUser) {
        window.adobeIMS?.validateToken().catch(() => {
          window.adobeid.redirect_uri = window.location.href;
          window.adobeIMS?.signIn();
        });
      }
      document.dispatchEvent(IMSEvent);
    }
  };

  useEffect(() => {
    var currentLocaleData = locales.fetch();
    const imsData = new ImsData(onStateChanged);
    window.adobeid = imsData.adobeIdData;

    const pageDetail = {
      solution: {
        name: "status",
        version: "2022.3"
      },
      env: OMEGA_ENV,
      autoTrack: false
    };
    window.digitalData = {
      user: {
        id: "anonymous",
        corpId: "anonymous",
        corpName: "anonymous",
        authSystem: "anonymous",
        accountType: "anonymous",
        language: window.locale || "en:us"
      },
      page: pageDetail
    };


    // ImsWebHelper.addScript(ImsWebConstants.fedGlobalNavPolyfills);
    // ImsWebHelper.addScript(ImsWebConstants.fedGlobalNav);

    ImsWebHelper.injectAdobeIms().then(() => {
      ImsWebHelper.IMSLoaded();
      ImsWebHelper.addScript(ImsWebConstants.omegaLocation).then(() => {
        if (window._satellite) { // OMEGA tigger
          window._satellite.pageBottom();
        }
      });

    });

    locales.setLocales(currentLocaleData);
    setAppConfig({
      languageData: currentLocaleData,
      menu: 'Overview',
    });

  }, []);


  return (
    <div className="App">
      <Router>
        <ConfigContext.Provider value={context}>
          <Header />
          <Switch>
            {/* <Route path="/cloud/:cloudName">
              <Cloud />
            </Route> */}
            <Route path="/cloud/:cloudName/:eventId?">
              <Cloud />
            </Route>
            {/* <Route path="/:locale/cloud/:cloudName">
              <Cloud />
            </Route> */}
            <Route path="/:locale/cloud/:cloudName/:eventId?">
              <Cloud />
            </Route>
            <Route path="/products/:productId/:eventId?">
              <Events />
            </Route>
            <Route path="/:locale/products/:productId/:eventId?">
              <Events />
            </Route>
            <Route path="/history/:messageId">
              <HistoryRedirection />
            </Route>
            <Route path="/subscribe/:eventId/cloud/:cloudId?">
              <EventSubscription type="subscription" />
            </Route>
            <Route path="/:locale/subscribe/:eventId/cloud/:cloudId?">
              <EventSubscription type="subscription" />
            </Route>
            <Route path="/unsubscribe/:eventId/cloud/:cloudId?">
              <EventSubscription type="unsubscription" />
            </Route>
            <Route path="/:locale/unsubscribe/:eventId/cloud/:cloudId?">
              <EventSubscription type="unsubscription" />
            </Route>
            <Route path="/subscribe/:eventId/:productId?">
              <EventSubscription type="subscription" />
            </Route>
            <Route path="/:locale/subscribe/:eventId/:productId?">
              <EventSubscription type="subscription" />
            </Route>
            <Route path="/unsubscribe/:eventId/:productId?">
              <EventSubscription type="unsubscription" />
            </Route>
            <Route path="/:locale/unsubscribe/:eventId/:productId?">
              <EventSubscription type="unsubscription" />
            </Route>
            <Route path="/proactive-notifications/manage">
              <ManageSubscription />
            </Route>
            <Route path="/:locale/proactive-notifications/manage">
              <ManageSubscription />
            </Route>
            <Route path="/proactive-notifications/subscriptions/:type">
              <ProactiveNotification />
            </Route>
            <Route path="/:locale/proactive-notifications/product/unsubscribe-all">
              <PnsUnsubscribeAll />
            </Route>
            <Route path="/proactive-notifications/product/unsubscribe-all">
              <PnsUnsubscribeAll />
            </Route>
            <Route path="/:locale/proactive-notifications/product/unsubscribe-all">
              <PnsUnsubscribeAll />
            </Route>
            <Route path="/proactive-notifications/product/unsubscribe/:productId">
              <UnSubscribeProduct />
            </Route>
            <Route path="/:locale/proactive-notifications/product/unsubscribe/:productId">
              <UnSubscribeProduct />
            </Route>
            <Route path="/slack/landing">
              <SlackLanding />
            </Route>
            <Route path="/:locale/slack/landing">
              <SlackLanding />
            </Route>
            <Route path="/slack/error">
              <SlackError />
            </Route>
            <Route path="/:locale/slack/error">
              <SlackError />
            </Route>
            <Route path="/slack/success">
              <SlackSuccess />
            </Route>
            <Route path="/:locale/slack/success">
              <SlackSuccess />
            </Route>
            <Route path="/help/proactivesubscriptions" >
              <Help />
            </Route>
            <Route path="/nav" >
              <Home />
            </Route>
            <Route path="/:locale/nav" >
            </Route>
            <Route path="/:locale/:eventId?" >
              <Home />
            </Route>      
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>
          <Footer />
        </ConfigContext.Provider>
      </Router>
    </div>
  );
}

export default App;
